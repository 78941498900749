<template>
    <div
        v-if="intranet"
        class="page"
    >
        <div class="container padded">
            <h1>{{ $t("my_settings") }}</h1>
            <tabs :links="tabs"/>
            <router-view
                key="tabs"
                pbottom-large
            />
        </div>
    </div>
</template>

<script>
import Tabs from "@web/components/Tabs";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";

export default {
    name: "UserSettings",
    components: {
        Tabs
    },
    data() {
        return {
            tabs: [
                { to: "user-integrations", title: this.$t("integrations") },
                { to: "notifications", title: this.$t("notifications") },
            ],
        };
    },
    computed: {
        intranet() {
            return this.$store.getters[INTRANET_MODULE_NAME + INTRANET];
        }
    }
};
</script>

<style scoped>

</style>
